import * as React from 'react'
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Alert, Button, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { UserResources } from '../types/user.types'
import { changePassword, getUserResourceInformation, toggleSaveHistory } from '../services/user.service'
import { AccountBox } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '0.5px solid #000',
    borderRadius: '4px',
    boxShadow: 24,
    p: 4,
}

const Profile: React.FC = () => {
    const [t, _i18n] = useTranslation()
    const [open, setOpen] = useState<boolean>(false)
    const [userData, setUserData] = useState<UserResources>()
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [successful, setSuccessful] = useState<boolean | undefined>()
    const [message, setMessage] = useState<string>('')
    const [oldPassword, setOldPassword] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [allowSave, setAllowSave] = useState<boolean>(false)

    const handlePasswordChange = () => {
        changePassword(oldPassword, newPassword)
            .then((_resp) => {
                setSuccessful(true)
                setMessage(t('Password changed.'))
            })
            .catch((_err: any) => {
                setSuccessful(false)
                setMessage(t('Could not change password.'))
            })
    }

    const handleAllowSaveChange = () => {
        toggleSaveHistory().then((_resp) => {
            setAllowSave(!allowSave)
        })
    }

    useEffect(() => {
        if (open) {
            getUserResourceInformation().then((userDataResponse) => {
                setUserData(userDataResponse.data)
                setAllowSave(userDataResponse.data.allowSave)
            })
        }
    }, [open])

    return (
        <Box component='div'>
            <AccountBox onClick={handleOpen} />
            <Modal
                title={t('Profile')}
                open={open}
                onClose={handleClose}
                aria-labelledby={t('Profile')}
                aria-describedby='modal-modal-description'
            >
                <Box sx={style}>
                    <Typography id='modal-modal-title' variant='h6' component='h2'>
                        {userData?.username ?? ''} ({userData?.mail})
                    </Typography>
                    <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                        {t('Remaining simplification requests:')}{' '}
                        {userData?.constraints?.simplification === -1 || userData?.isAdmin ? '∞' : userData?.constraints?.simplification}
                    </Typography>
                    <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                        {t('Remaining anonymization requests:')}{' '}
                        {userData?.constraints?.anonymization === -1 || userData?.isAdmin ? '∞' : userData?.constraints?.anonymization}
                    </Typography>
                    <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                        {t('Can compound split:')}{' '}
                        {userData?.constraints?.canCompoundSplit || userData?.isAdmin ? '✓' : '❌'}
                    </Typography>
                    <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                        {t('Expiration Date:')}{' '}
                        {userData?.constraints?.enabledUntil === undefined || userData?.isAdmin
                            ? t('Never')
                            : userData?.constraints.enabledUntil.toString()}
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox onChange={handleAllowSaveChange} checked={allowSave} />}
                        label={t('Allow us to save your data to improve our products')}
                    />
                    <Box sx={{ mt: 1 }}>
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            id='oldPassword'
                            label={t('Old password')}
                            name='oldPassword'
                            autoComplete='old-password'
                            type='password'
                            autoFocus
                            value={oldPassword}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setOldPassword(event.target.value)
                            }}
                        />
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            name='newPassword'
                            label={t('New password')}
                            type='password'
                            id=''
                            autoComplete='current-password'
                            value={newPassword}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setNewPassword(event.target.value)
                            }}
                        />
                        <Button onClick={handlePasswordChange} fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
                            {t('Change password')}
                        </Button>
                    </Box>
                    {successful === false && (
                        <>
                            <Alert severity='error'>{message}</Alert>
                        </>
                    )}
                    {successful && <>{<Alert severity='success'>{message}</Alert>}</>}
                </Box>
            </Modal>
        </Box>
    )
}

export default Profile

// TODO: Remove this file

import axios from 'axios'
import authHeader from './auth.service'
import { AnonymisationOutput, AnonymisationRequestdata } from '../types/anonymisation.types'

const ANONYMISATION_URL = (process.env.REACT_APP_API_URL || 'http://localhost:8000/') + 'anonymize'

export const getAnonymization = async (data: AnonymisationRequestdata): Promise<AnonymisationOutput | undefined> => {
    const response = await axios.post(ANONYMISATION_URL, data, { headers: authHeader() })
    if (response.status == 401) {
        return undefined
    }
    return response.data as AnonymisationOutput
}

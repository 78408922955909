import * as React from 'react'
import { T2KRoutes } from '../types/general.types'
import { useNavigate } from 'react-router-dom'
import { simplifyRequest, HTTPERROR } from '../services/simplification.service'
import { getAnonymization } from '../services/anonymisation.service'
import { getCurrentUserToken } from '../services/auth.service'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    SwipeableDrawer,
    TextField,
    List,
    ListItem,
    Checkbox,
    Tooltip,
    Typography,
} from '@mui/material'
import { SimplificationDecodingStrategy, SimplificationRequest } from '../types/simplification.types'
import { getPagedUserHistory, sendHistoryFeedback, deleteHistoryEntry } from '../services/history.service'
import HistoryIcon from '@mui/icons-material/History'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import FileUploader from './FileUploader'
import { HistoryEntry } from '../types/history.types'
import { HistoryEntryComponent } from './HistoryEntry'
import SendIcon from '@mui/icons-material/Send'
import CopyAllIcon from '@mui/icons-material/CopyAll'
import SubjectIcon from '@mui/icons-material/Subject'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { summarize } from '../services/summarization.service' // TODO: Remove this
import { AnonymisationOutput } from '../types/anonymisation.types'

type Props = {
    currentUserToken: string | undefined
}

const fileTypes = ['TXT']

const Simplification: React.FC<Props> = () => {
    const [t, _i18n] = useTranslation()
    const [processing, setProcessing] = useState<boolean>(false)
    const [inputText, setInputText] = useState<string>('')
    const [batchSize, _setBatchSize] = useState<number>(1)
    const [decodingStrategy, _setDecodingStrategy] = useState<SimplificationDecodingStrategy>(
        SimplificationDecodingStrategy.BeamSeach,
    )
    const [_file, setFile] = useState(null)
    const [state, setState] = useState({
        left: false,
    })
    const [userHistory, _setUserHistory] = useState<HistoryEntry[]>([])
    const [selectedHistoryId, setSelectedHistoryId] = useState<number | undefined>()
    const [currentPageNumber, _setCurrentPageNumber] = useState<number>(1)
    const [feedback, setFeedback] = useState<boolean | undefined>()
    const [currentText, setCurrentText] = useState<string>('')
    const [tempText, setTempText] = useState<string>('')

    const navigate = useNavigate()

    const copyToClipboard = () => {
        navigator.clipboard.writeText(currentText)
    }

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return
        }

        if (open) {
            getPagedUserHistory(currentPageNumber).then((pagedHistoryResponse) => {
                _setUserHistory(pagedHistoryResponse.data)
                setState({ ...state, left: open })
            })
        }
        if (!open && (event.target as HTMLInputElement).type === 'checkbox') {
            return
        }
        setState({ ...state, left: open })
    }

    const uiIsActive = () => {
        return processing
    }

    const handleUpload = (file: any) => {
        setFile(file)
        const reader = new FileReader()
        reader.readAsText(file)
        reader.onload = (event) => {
            const content = event.target?.result
            setInputText(content as string)
        }
        setTempText('')
        setCurrentText('')
    }

    const handleHistory = (entry: HistoryEntry) => {
        setCurrentText(entry.outputText) // Simplified text
        setInputText(entry.inputText) // Input text
        setSelectedHistoryId(entry.id)
        setFeedback(entry.feedbackIsPositive)
        setTempText('')
        console.log(selectedHistoryId)
    }

    const deleteHistory = (entry: HistoryEntry): void => {
        deleteHistoryEntry(entry.id).then((_) => {
            getPagedUserHistory(currentPageNumber).then((pagedHistoryResponse) => {
                _setUserHistory(pagedHistoryResponse.data)
            })
        })
    }

    const setFeedbackCall = (positiveFeedback: boolean) => {
        if (selectedHistoryId) {
            sendHistoryFeedback(selectedHistoryId, { feedback: positiveFeedback })
            setFeedback(positiveFeedback)
        }
    }

    const handleAnonymize = (value: boolean) => {
        if (value) {
            setTempText(currentText)
            setProcessing(true)
            getAnonymization({
                inputText: currentText,
                entities: [
                    'Person',
                    'Phone number',
                    'Postcode',
                    'Street',
                    'Date',
                    'Url',
                    'City',
                    'Company registration number',
                    'Tax',
                    'Email',
                    'Organization',
                    'Iban',
                    'Bic',
                    'Country',
                    'Amount of money',
                    'Address',
                ],
                batchSize: 4,
                replaceToken: t('[REDACTED]'),
            }).then((response: AnonymisationOutput | undefined) => {
                if (response != undefined) {
                    setCurrentText(response.resultText)
                    setProcessing(false)
                } else {
                    alert(t('No more anonymization requests available. Please contact the support.'))
                }
            })
        } else {
            setCurrentText(tempText)
            setTempText('')
        }
    }

    // TODO: Remove this
    const handleSummarize = () => {
        setTempText('')
        setCurrentText('')
        setProcessing(true)
        summarize(inputText).then((response: string) => {
            setInputText(response.trim())
            setProcessing(false)
        })
    }

    const startSimplification = async () => {
        setCurrentText('')
        setTempText('')
        console.log('Starting simplification')
        setProcessing(true)

        const requestData = new SimplificationRequest(
            inputText, // inputText
            true, // stream
            batchSize, // batchSize
            decodingStrategy, // decodingStrategy
            256, // maxNewTokens
            'A1', // simplificationLevel
            true, // separateCompounds
            false, // filterComplexWords
        )

        try {
            await simplifyRequest(
                setCurrentText, // Callback to update the text
                setProcessing, // Callback to handle completion
                setSelectedHistoryId, // Callback to update history
                requestData,
            )
            console.log('Simplification complete')
        } catch (error: any) {
            if (error.message == HTTPERROR) {
                alert(t('No more simplification requests remaining. Please contact support'))
            }
            console.error('Simplification failed:', error)
        } finally {
            setProcessing(false)
        }
    }

    useEffect(() => {
        if (!getCurrentUserToken()) {
            console.log('Not logged in, navigating to login.')
            navigate(T2KRoutes.Login)
        }
    }, [navigate])

    return (
        <Container component='main'>
            <SwipeableDrawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <Box role='presentation' onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                    <List>
                        {userHistory.map((historyEntry, index) => (
                            <ListItem
                                key={index}
                                disablePadding
                                onClick={(event) =>
                                    (event.target as HTMLInputElement).type !== 'checkbox'
                                        ? handleHistory(historyEntry)
                                        : undefined
                                }
                            >
                                <HistoryEntryComponent
                                    historyEntry={historyEntry}
                                    handleHistory={handleHistory}
                                    historyEntryIndex={index + 1 + 10 * (currentPageNumber - 1)}
                                    deleteHistoryEntry={deleteHistory}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </SwipeableDrawer>

            <Box>
                <Typography variant='h3' component='h3' align='center'>
                    {t('Text Simplification')}
                </Typography>
                <FileUploader handleChange={handleUpload} name='file' types={fileTypes} />
                <Grid container spacing={2}>
                    <Grid item xs={currentText === '' ? 12 : 6}>
                        <TextField
                            fullWidth
                            variant='standard'
                            label={t('Input Text')}
                            InputLabelProps={{
                                sx: {
                                    fontSize: '30px',
                                },
                            }}
                            InputProps={{
                                sx: {
                                    paddingTop: '20px',
                                },
                            }}
                            value={inputText}
                            minRows={25}
                            maxRows={25}
                            multiline
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setInputText(event.target.value)
                                setSelectedHistoryId(undefined)
                                setCurrentText('')
                            }}
                        />
                        <Tooltip title={t('Simplification History')} placement='top' arrow>
                            <Checkbox
                                icon={<HistoryIcon />}
                                checkedIcon={<HistoryIcon />}
                                onClick={toggleDrawer(true)}
                                style={{
                                    color: 'grey',
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    {currentText !== '' && (
                        <Grid item xs={6}>
                            <Grid container>
                                <TextField
                                    fullWidth
                                    multiline
                                    label={t('Simplified Text')}
                                    InputLabelProps={{
                                        sx: {
                                            fontSize: '30px',
                                        },
                                    }}
                                    InputProps={{
                                        sx: {
                                            paddingTop: '20px',
                                        },
                                    }}
                                    variant='standard'
                                    value={currentText}
                                    minRows={25}
                                    maxRows={25}
                                />

                                <Grid item xs={9}>
                                    <Tooltip title={t('Copy simplification')} placement='top' arrow>
                                        <Checkbox icon={<CopyAllIcon />} onClick={copyToClipboard} />
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={3}>
                                    <Checkbox
                                        icon={<ThumbUpOffAltIcon />}
                                        checkedIcon={<ThumbUpAltIcon />}
                                        onClick={() => setFeedbackCall(true)}
                                        checked={feedback === true}
                                    />
                                    <Checkbox
                                        icon={<ThumbDownOffAltIcon />}
                                        checkedIcon={<ThumbDownAltIcon />}
                                        onClick={() => setFeedbackCall(false)}
                                        checked={feedback === false}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <Button
                            variant='contained'
                            fullWidth
                            onClick={handleSummarize}
                            disabled={inputText === '' || processing}
                            startIcon={<SubjectIcon />}
                        >
                            {t('Summarize input text')}
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant='contained'
                            onClick={() => {
                                startSimplification()
                                setFeedback(undefined)
                            }}
                            startIcon={<SendIcon />}
                            fullWidth
                            disabled={inputText === '' || processing}
                        >
                            {t('Start Simplification')}
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant='contained'
                            fullWidth
                            onClick={() => handleAnonymize(tempText === '')}
                            disabled={currentText === '' || uiIsActive()}
                            startIcon={tempText !== '' ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        >
                            {tempText === '' ? t('Anonymize simplification') : t('Reset anonymization')}
                        </Button>
                    </Grid>
                    <Grid
                        container
                        padding={2}
                        spacing={0}
                        justifyContent='center'
                        alignItems='center'
                        direction='column'
                    >
                        {uiIsActive() ? <CircularProgress size={20} /> : ''}
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default Simplification
